import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IComponentController, IScope } from 'angular';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';

interface IComposantCardMultiOccurrenceAdvanced extends IComponentController {
    libelles: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
}

/* @ngInject */
export default function CardMultiAdvancedController($scope: IScope) {
    const vm: IComposantCardMultiOccurrenceAdvanced = this;

    vm.$onInit = function $onInit() {
        vm.libelles = vm.libelles || (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles);

        vm.multiOccurrenceOptions.fonctions = {
            importation: false,
            introspection: false,
            afficherMenuSettingDansEntete: true,
            suiviModification: true,
            criteresSuggeresVisibles: false,
            ...vm.multiOccurrenceOptions.fonctions
        }

        if (vm.monoOccurrenceEcranCtrl &&
            vm.monoOccurrenceEcranCtrl.monoOccurrenceOptions &&
            vm.monoOccurrenceEcranCtrl.monoOccurrenceOptions.infosMonetaires) {
            $scope.$watch('::vm.multiOccurrence', (multiOccurrence: IMultiOccurrence) => {
                if (typeof multiOccurrence !== 'undefined') {
                    vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(multiOccurrence);
                }
            });
        }
    }
}
