import {IComponentOptions, module} from 'angular';
import DataBarController from './ex-data-bar.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-bar', []).component('exDataBar', DataBarFactory());

function DataBarFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            Binding.DATA.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            {
                maxValue: '<?',
                classeCouleur: '@?',
                affichageFraction: '<?',
                menu: '<?',
                ratio: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: DataBarController,
        template: require('./ex-data-bar.html')
    };
}
