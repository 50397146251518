import {module} from 'angular';
import InfosComplementairesController from './ex-infos-complementaires.controller';
import Binding from '../../helpers/binding.helper';
import IComponentOptions = angular.IComponentOptions;

export default module('core.components.ex-infos-complementaires', []).component('exInfosComplementaires', InfosComplementairesFactory());

function InfosComplementairesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE,
            Binding.ICON.OPTIONAL,
            {
                lblTitre: '<?',
                closeAction: '<?',
                compteur: '=?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: InfosComplementairesController,
        controllerAs: 'vm',
        template: require('./ex-infos-complementaires.html')
    };
}
