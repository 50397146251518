import {IScope, ITimeoutService} from 'angular';
import {IMenuItem} from '../../services/menu/menu-item.service';
import {IComposantMultiOccurrence} from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import {IMenu} from "../../services/menu/menu.service";

interface ISpeedDialCircle {
    mdOpened: boolean;
    opened: boolean;
    unique: boolean;
    data: any;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    menu: IMenu;

    $onInit(): void;
    closeFab(): void;
    getMenuItemDisabled(menuItem: IMenuItem): boolean;
    isMenuItemIconDynamique(menuItem: IMenuItem): boolean;
    getMenuItemClass(menuItem: IMenuItem): string;
    action($event: any, icon: any, data: any, icons: any): void;
}

/* @ngInject */
export default function FabSpeedDialCircleController($scope: IScope,
                                                     $timeout: ITimeoutService) {
    const vm: ISpeedDialCircle = this;

    const DIRECTIONS_TOOLTIP = ['top', 'right', 'right', 'bottom', 'bottom', 'left', 'left'];

    vm.$onInit = $onInit;
    vm.closeFab = closeFab;
    vm.action = action;
    vm.getMenuItemDisabled = getMenuItemDisabled;
    vm.isMenuItemIconDynamique = isMenuItemIconDynamique;
    vm.getMenuItemClass = getMenuItemClass;

    function $onInit(): void {
        $scope.$watch('vm.opened', (newval: boolean, oldval: boolean) => {
            vm.mdOpened = newval;
        });

        vm.menu.listeMenuItem.forEach((menuItem: IMenuItem, index: number) => {
            if (menuItem.lblTitre) {
                menuItem.directionTooltip = DIRECTIONS_TOOLTIP[index];
            }
        })
    }

    function action($event: any, menuItem: IMenuItem, data: any) {
        menuItem.action($event, data, vm.multiOccurrenceCtrl.multiOccurrence);
    }

    function closeFab() {
        if (vm.unique) {
            vm.multiOccurrenceCtrl.multiOccurrence.activeRowCleint = null;
        } else {
            vm.data.$opened = false;
        }
    }

    function getMenuItemDisabled(menuItem: IMenuItem) {
        if (menuItem.disabled instanceof Function) {
            return menuItem.disabled();
        } else {
            return menuItem.disabled;
        }
    }

    function getMenuItemClass(menuItem: IMenuItem): string {
        if (menuItem.class instanceof Function) {
            return menuItem.class();
        } else {
            return menuItem.class;
        }
    }

    function isMenuItemIconDynamique(menuItem: IMenuItem): boolean {
        return menuItem.icon instanceof Function;
    }
}
