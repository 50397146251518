import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import MultiOccurrenceHierarchieController from "./ex-multi-occurrence-hierarchie.controller";

export default module('core.components.ex-multi-occurrence-hierarchie', []).component('exMultiOccurrenceHierarchie', MultiOccurrenceHierarchieFactory());

function MultiOccurrenceHierarchieFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            {
                multiOccurrence: '=?multiOccurrenceOut',
                expandedRows: '<?',
                datalistOriginal: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceCtrl: '^^exMonoOccurrence',
            monoOccurrenceEcranCtrl: '^^exMonoOccurrenceEcran'
        },
        controller: MultiOccurrenceHierarchieController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-hierarchie.html')
    };
}
