import {IComponentOptions, module} from 'angular';
import CardQuestionnaireController from './ex-card-questionnaire.controller';

export default module('core.components.ex-card-questionnaire', []).component('exCardQuestionnaire', CardQuestionnaireFactory());

function CardQuestionnaireFactory(): IComponentOptions {
    return {
        bindings: {
            formDataList: '=?',
            multiOccurrence: '=?multiOccurrenceOut'
        },
        require: {
            blocCtrl: '^exBloc',
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceCtrl: '?^^exMonoOccurrence'
        },
        controller: CardQuestionnaireController,
        controllerAs: 'vm',
        template: require('./ex-card-questionnaire.html')
    };
}
