/**
 * Composant représentant un intervalle entre 2 champs du même type
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputIntervalleController from "./ex-input-intervalle.controller";

export default module('core.components.ex-input-intervalle', []).component('exInputIntervalle', InputIntervalleFactory());

function InputIntervalleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.LIBELLES,
            Binding.VALUE,
            Binding.DISABLED,
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.OCCURRENCE,
            Binding.READONLY.OPTIONAL,
            {
                titre: '@?',
                required : '<?ngRequired',
                resourceParams: '<?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputIntervalleController,
        template: require('./ex-input-intervalle.html')
    };
}
