import { IComponentController, IPromise } from 'angular';
import IDialogOptions = angular.material.IDialogOptions;
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import { IDialog } from '../../services/dialog.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { ICompteurPiecesJointesDataType } from '../../services/data-types/compteur-pieces-jointes-data-type.service';
import { IFonctionTransversaleResourceClass } from '../../resources/fonction-transversale.resource';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';

interface IBoutonCompteurPiecesJointes extends IComponentController {
    menuItem: IMenuItem;
    multiOccurrence: IMultiOccurrence;
    ecranContextCtrl: IEcranContextController;
    data: any
    dataType: ICompteurPiecesJointesDataType;
    col: string;
}

/* @ngInject */
export default function BoutonCompteurPiecesJointesController(DialogPiecesJointes: IDialog,
    MenuItem: IMenuItemClass,
    notificationHandler: INotificationHandler,
    parametresSecurite: IParametresSecuriteService,
    exLibelleFilter: IFilterLibelle,
    FonctionTransversaleResource: IFonctionTransversaleResourceClass,
    MonoOccurrence: IMonoOccurrenceClass) {
    const vm: IBoutonCompteurPiecesJointes = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.menuItem = new MenuItem(
            openDialogPiecesJointes,
            {
                iconButton: true,
                fonction: 'BOUPIJ',
                icon: 'attach_file',
                hidden: typeof vm.data[vm.col] === 'undefined' || vm.data[vm.col] === null
            }
        );
    }

    function openDialogPiecesJointes(event: MouseEvent) {
        event.preventDefault();

        fetchTitre()
            .then((lblTitre) => {
                const mnemonique = (vm.dataType && vm.dataType.params && vm.dataType.params.mnemonique) ? vm.dataType.params.mnemonique : vm.multiOccurrence.mnemonique;

                const resourceParams = {
                    ...vm.multiOccurrence.etatSelected.criteresSuggeresData,
                    ...vm.multiOccurrence.getResourceParamsValues(),
                    colref: vm.col,
                    mnemonique
                };

                const dialogOptions: IDialogOptions = {
                    locals: {
                        monoOccurrence: new MonoOccurrence({
                            stateParams: vm.multiOccurrence.stateParams,
                            ecranDetails: vm.multiOccurrence.ecranDetails,
                            srccod: vm.multiOccurrence.srccod,
                            bloc: vm.multiOccurrence.bloc,
                            id: vm.data[vm.multiOccurrence.cycleCleint || vm.multiOccurrence.cleint],
                            resourceParams
                        }),
                        ecranContext: vm.ecranContextCtrl,
                        lblTitre: `${exLibelleFilter('G_LBL_PIECES_JOINTES_POUR')} ${lblTitre}`
                    }
                };

                DialogPiecesJointes.show(dialogOptions).finally(() => {
                    vm.multiOccurrence.fetchDataList();
                });
            })
            .catch((err) => {
                notificationHandler.erreur({
                    error: err,
                    lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                    lblMessage: 'G_MSG_CHARG_DONNEES',
                    confirmAction() {
                        return openDialogPiecesJointes(event);
                    }
                });

            });
    }

    function fetchTitre(): IPromise<any> {
        const colref = vm.col;
        const srccod = vm.multiOccurrence.getNomSourceDetails();

        const params = {
            ...vm.multiOccurrence.getDataListQueryParams(),
            colref,
            srccod,
            cleref: vm.data[vm.multiOccurrence.cleint],
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        return FonctionTransversaleResource.getTitre(params).$promise
            .then((result) => {
                return result.titre;
            });
    }
}
