import {IComponentController, ITimeoutService} from 'angular';
import {IDataSourceController} from "../ex-data-source/ex-data-source.component";
import {IComposantMonoOccurrence} from "../ex-mono-occurrence/ex-mono-occurrence.controller";
import {IDefaultsService} from "../../services/utils/defaults.service";

interface IComposantDataBarList extends IComponentController {
    cols: Array<string|IColonneBarOptions>;
    colsOptions: Array<IColonneBarOptions>;
    forcedMaxValue: number;
    maxValue: number;
    data: any;
    ready: boolean;
    affichageFraction: boolean;
    dataSourceCtrl: IDataSourceController;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
}

export interface IColonneBarOptions {
    nom: string;
    classeCouleur?: string;
    finGroupe?: boolean;
    affichageFraction?: boolean;
}

/* @ngInject */
export default function DataBarListController(defaults: IDefaultsService, $timeout: ITimeoutService) {
    const vm: IComposantDataBarList = this;
    const OPTIONS_DEFAUT = {
        affichageFraction: false
    };

    vm.$onInit = $onInit;

    function $onInit() {
        const colOptionsBase = defaults({
            affichageFraction: vm.affichageFraction,
            maxValue: vm.forcedMaxValue
        }, OPTIONS_DEFAUT);

        vm.data = vm.data ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data);

        vm.colsOptions = vm.cols.map((col: string|IColonneBarOptions) => {
            if (typeof col === 'string') {
                return {
                    nom: col,
                    ...colOptionsBase
                }
            } else {
                return {...colOptionsBase, ...col};
            }
        });

        vm.ready = true;
        if (!vm.forcedMaxValue) {
            initMaxValue();
            vm.monoOccurrenceCtrl.monoOccurrence.on('softDataUpdate', initMaxValue);
        }
    }

    function initMaxValue() {
        let maxValue = 0;
        vm.colsOptions.map((colOption: IColonneBarOptions) => colOption.nom)
            .forEach((col: string) => {
                const value = (typeof vm.data[col] === 'string') ?
                    Number(vm.data[col].replace(/[^0-9.]/g, '')) || 0 :
                    Number(vm.data[col]) || 0;

                if (value > maxValue) {
                    maxValue = value;
                }
            });

        vm.maxValue = maxValue;
    }
}
