import {
    IAugmentedJQuery,
    IComponentController
} from 'angular';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { IFormulaireClass } from '../../services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantInfosComplementairesFinance extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    compteur: number;
}

/* @ngInject */
export default function InfosComplementairesFinanceController(Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    $element: IAugmentedJQuery) {

    const vm: IComposantInfosComplementairesFinance = this;

    vm.$onInit = $onInit;

    function $onInit() {
        $element.closest('md-content.ex-mono-occurrence-ecran-content').animate({
            scrollTop: $element.prop('offsetTop') - 8
        }, 200);

        const cleint = vm.monoOccurrence.cleint;

        vm.multiOccurrenceOptions = {
            srccod: 'mc1006',
            stateParams: vm.ecranContextCtrl.stateParams,
            parentId: vm.monoOccurrence.data[cleint],
            colonnesVisibles: [
                'rvilibele',
                'iccval',
            ],
            resourceParams: {
                ecrcleint: vm.ecranContextCtrl.ecranDetails.ecrcleint,
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                vaesysmnetab: vm.monoOccurrence.mnemonique,
                cleintref: vm.monoOccurrence.data[vm.monoOccurrence.cleint]
            },
            formulaire: new Formulaire([
                new FormulaireItem('rvicleint', { largeur: 33.33, required: true, resourceParams: { vaesysmnetab: vm.monoOccurrence.mnemonique } }),
                new FormulaireItem('iccval', { largeur: 66.66, required: true }),
                new FormulaireItem('vaesysmnetab', { hidden: true, default: vm.monoOccurrence.mnemonique })
            ]),
            fonctions: Object.assign({
                nouveau: true,
                recherche: true,
                rechercheParColonne: true,
                filtrer: true,
                enregistrerUnEtat: false,
                selectionnerUnEtat: true,
                exportation: true,
                pagination: true,
                importation: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                reinitialiser: false,
                saveEtatTemporaire: false
            }, {}),
            onDelete: () => vm.compteur--
        };
    }

    vm.onSaveNewAction = () => vm.compteur++
}
