import {module} from 'angular';
import Binding from '../../helpers/binding.helper';
import IComponentOptions = angular.IComponentOptions;
import InfosComplementairesFinanceController from './ex-infos-complementaires-finance.controller';

export default module('core.components.ex-infos-complementaires-finance', []).component('exInfosComplementairesFinance', InfosComplementairesFinanceFactory());

function InfosComplementairesFinanceFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            {
                monoOccurrence: '<',
                lblTitre: '<?',
                closeAction: '<?',
                compteur: '=?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: InfosComplementairesFinanceController,
        controllerAs: 'vm',
        template: require('./ex-infos-complementaires-finance.html')
    };
}
