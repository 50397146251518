import {IComponentOptions, module} from 'angular';
import CalendrierController from './ex-calendrier.controller';
import Binding from "../../helpers/binding.helper";

export default module('core.components.ex-calendrier', []).component('exCalendrier', CalendrierFactory());

function CalendrierFactory(): IComponentOptions {
    return {
        bindings: Binding(Binding.MULTI_OCCURRENCE),
        controller: CalendrierController,
        controllerAs: 'vm',
        template: require('./ex-calendrier.html')
    };
}
