import { IAugmentedJQuery, IComponentController, IFilterNumber, IScope, ITimeoutService } from 'angular';
import { IDataSourceController } from "../ex-data-source/ex-data-source.component";
import { IComposantMonoOccurrence } from "../ex-mono-occurrence/ex-mono-occurrence.controller";
import { ILibelles } from "../../services/libelles.service";

interface IComposantDataBar extends IComponentController {
    value: number;
    col: string;
    classeCouleur: string;
    maxValue: any;
    data: any;
    libelles: ILibelles;
    dataSourceCtrl: IDataSourceController;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    ratio: string;
    ratioValeur: number;
    formattedRatioValeur: string;
}

/* @ngInject */
export default function DataBarController($element: IAugmentedJQuery,
    $scope: IScope,
    $timeout: ITimeoutService,
    numberFilter: IFilterNumber) {
    const vm: IComposantDataBar = this;
    const BAR_SELECTOR = '.ex-data-bar-ligne';

    vm.$onInit = function $onInit() {
        vm.data = vm.data ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data);

        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);

        vm.classeCouleur = vm.classeCouleur || 'ex-primary-bg';

        $scope.$watchCollection(() => [vm.data[vm.col], vm.maxValue], () => {
            initValues();
            $timeout(() => {
                const dataBar = $element.find(BAR_SELECTOR);
                const targetWidth = vm.value / vm.maxValue * 100;

                dataBar.animate({
                    width: `${targetWidth}%`
                }, 1000);
            }, 50);
        })
    }

    function initValues() {
        if (typeof vm.data[vm.col] === 'string') {
            vm.value = Number(vm.data[vm.col].replace(/[^0-9.]/g, '')) || 0;
        } else {
            vm.value = Number(vm.data[vm.col]) || 0;
        }

        if (vm.maxValue !== undefined) {
            vm.maxValue = (typeof vm.maxValue === 'string') ? Number(vm.maxValue.replace(/[^0-9.]/g, '')) || 0 : Number(vm.maxValue) || 0;
        } else {
            vm.maxValue = vm.value;
        }

        if (vm.ratio !== undefined && vm.data[vm.ratio]) {
            const valeurEntier = vm.data[vm.ratio];
            vm.ratioValeur = (vm.value * 100 / valeurEntier) - 100;
            vm.formattedRatioValeur = `(${vm.ratioValeur > 0 ? '+' : ''}${numberFilter(vm.ratioValeur, 0)}%) `;
        }
    }
}
