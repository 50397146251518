import {IComponentOptions, module} from 'angular';
import HierarchieItemController from './ex-hierarchie-item.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-hierarchie-item', []).component('exHierarchieItem', HierarchieItemFactory());

function HierarchieItemFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                multiOccurrence: '<',
                data: '<',
                niveau: '<?',
                expandedRows: '<?'
            }
        ),
        require: {
            hierarchieItemCtrl: '^^?exHierarchieItem'
        },
        controller: HierarchieItemController,
        controllerAs: 'vm',
        template: require('./ex-hierarchie-item.html')
    };
}
