import {IComponentController, IFormController, IScope, ITimeoutService} from 'angular';
import {IDefaultsService} from "../../services/utils/defaults.service";
import {IFormulaire, IFormulaireClass} from "../../services/formulaire/formulaire.service";
import {IFormulaireItemClass, IFormulaireItemOptions} from "../../services/formulaire/formulaire-item.service";
import {IIntervalleDataType} from "../../services/data-types/intervalle-data-type.service";
import {IDateHeureDataTypeClass} from "../../services/data-types/date-heure-data-type.service";
import {IFilterLibelle} from "../../filters/ex-libelle.filter";
import {IOccurrence} from "../../services/occurrence.service";
import {INumberDataTypeClass} from "../../services/data-types/number-data-type.service";
import {IDateDataTypeClass} from "../../services/data-types/date-data-type.service";
import {IDataType} from "../../services/data-types/data-type.service";
import { ILovDataTypeClass } from '../../services/data-types/lov-data-type.service';

export interface IComposantInputIntervalle extends IComponentController {
    nameElement: string;
    dataType: IIntervalleDataType;
    sousDataType: IDataType;
    formCtrl: IFormController;
    formulaireIntervalle: IFormulaire;
    nameElementIntervalleMin: string;
    nameElementIntervalleMax: string;
    lblChampMin: string;
    lblChampMax: string;
    required: boolean;
    occurrence: IOccurrence;
    resourceParams: any;
    isValid(): boolean;
}

/* @ngInject */
export default function InputIntervalleController($scope: IScope,
                                                  $timeout: ITimeoutService,
                                                  NumberDataType: INumberDataTypeClass,
                                                  DateDataType: IDateDataTypeClass,
                                                  DateHeureDataType: IDateHeureDataTypeClass,
                                                  LovDataType: ILovDataTypeClass,
                                                  Formulaire: IFormulaireClass,
                                                  FormulaireItem: IFormulaireItemClass,
                                                  defaults: IDefaultsService,
                                                  exLibelleFilter: IFilterLibelle) {
    const vm: IComposantInputIntervalle = this;

    vm.$onInit = $onInit;
    vm.isValid = isValid;

    function $onInit() {
        defaults(vm, {
            lblChampMin: 'G_LBL_DATE_DE',
            lblChampMax: 'G_LBL_DATE_A',
            nameElementIntervalleMin: `${vm.nameElement}_min`,
            nameElementIntervalleMax: `${vm.nameElement}_max`,
            sousDataType: vm.dataType.dataTypeChamps
        });

        if (vm.sousDataType instanceof DateHeureDataType && !(<any>vm.occurrence.libelles)[`${vm.lblChampMin}_HEURE`]) {
            //On doit ajuster les libellés
            const lblValueMin = exLibelleFilter(vm.lblChampMin);
            const lblValueMax = exLibelleFilter(vm.lblChampMax);
            Object.assign(vm.occurrence.libelles,
                {
                    [`${vm.lblChampMin}_HEURE`]: lblValueMin,
                    [`${vm.lblChampMax}_HEURE`]: lblValueMax,
                });
            Object.assign(vm.occurrence.libelles.$libelles,
                {
                    [`${vm.lblChampMin}_HEURE`]: lblValueMin,
                    [`${vm.lblChampMax}_HEURE`]: lblValueMax,
                });
        }
        vm.formulaireIntervalle = getFormulaireIntervalle();

        // On attend que le formulaire soit accessible
        $scope.$watch('::vm.formCtrl[vm.nameElement]', () => {
            if (vm.formCtrl[vm.nameElement]) {
                const colsValidation = {
                    min: vm.nameElementIntervalleMin,
                    max: vm.nameElementIntervalleMax
                };

                if (vm.sousDataType instanceof DateHeureDataType) {
                    colsValidation.min = `${vm.nameElementIntervalleMin}_date`;
                    colsValidation.max = `${vm.nameElementIntervalleMax}_date`;
                }


                $timeout(() => {
                    vm.formCtrl[vm.nameElementIntervalleMin].$viewChangeListeners.push(() => validatePartiel(colsValidation.max), () => updateValue());
                    vm.formCtrl[vm.nameElementIntervalleMax].$viewChangeListeners.push(() => validatePartiel(colsValidation.min), () => updateValue());

                    // Les 2 parties de l'intervalle doivent être présente si une partie est alimentée
                    vm.formCtrl[colsValidation.min].$validators.requiredIntervalle = function (value: any) {
                        return Boolean(!isEmptyValue(value) || !vm.formCtrl[colsValidation.max].$viewValue);
                    };
                    vm.formCtrl[colsValidation.max].$validators.requiredIntervalle = function (value: any) {
                        return Boolean(!isEmptyValue(value) || !vm.formCtrl[colsValidation.min].$viewValue);
                    };

                    if (hasCustomValidations()) {
                        initCustomValidations();
                    }
                })
            }
        });
    }

    function initCustomValidations() {
        $scope.$watch(() => {
            return (
                (vm.formCtrl[vm.nameElementIntervalleMin] && vm.formCtrl[vm.nameElementIntervalleMin].$invalid) ||
                (vm.formCtrl[vm.nameElementIntervalleMax] && vm.formCtrl[vm.nameElementIntervalleMax].$invalid)
            );
        }, (isInvalid: boolean) => {
            if (isInvalid) {
                vm.formCtrl[vm.nameElement].$setValidity('validateApi', true);
            }
        });

        if (vm.sousDataType instanceof NumberDataType) {
            vm.formCtrl[vm.nameElement].$validators.validIntervalle = function (value: Array<string|number>) {
                return Boolean(
                    !value || !value.length || (isEmptyValue(value[0]) || isEmptyValue(value[1])) || Number(value[0]) <= Number(value[1])
                );
            };
        } else if (vm.sousDataType instanceof LovDataType) {
            vm.formCtrl[vm.nameElement].$validators.validIntervalle = function (value: Array<string|number>) {
                return !value || !value.length || (isEmptyValue(value[0]) || isEmptyValue(value[1])) || value[0] <= value[1];
            };
        } else {
            vm.formCtrl[vm.nameElement].$validators.validIntervalle = function (value: Array<string|number>) {
                return Boolean(
                    !value || !value.length || (isEmptyValue(value[0]) || isEmptyValue(value[1])) || new Date(value[0]) <= new Date(value[1])
                );
            };
        }
    }

    function isEmptyValue(value: string|number) {
        return typeof value === 'undefined' || value === null || value === '';
    }

    function validatePartiel(colValidation: string) {
        $timeout(()=> {
            vm.formCtrl[colValidation].$setTouched();
            vm.formCtrl[colValidation].$setDirty();
            vm.formCtrl[colValidation].$validate();
        });
    }

    function isValid(): boolean {
        return vm.formCtrl[vm.nameElementIntervalleMin].$valid && vm.formCtrl[vm.nameElementIntervalleMax].$valid;
    }

    function updateValue() {
        vm.formCtrl[vm.nameElement].$viewValue = [vm.formCtrl[vm.nameElementIntervalleMin].$viewValue, vm.formCtrl[vm.nameElementIntervalleMax].$viewValue];
        vm.formCtrl[vm.nameElement].$commitViewValue();
    }

    function getFormulaireIntervalle(): IFormulaire {
        const optionsBase: IFormulaireItemOptions = {
            largeur: 50,
            required: vm.required,
            dataType: vm.sousDataType,
            resourceParams: vm.resourceParams,
            readonly: false
        };
        return new Formulaire([
            new FormulaireItem(vm.nameElementIntervalleMin, {...optionsBase, titre: vm.lblChampMin}),
            new FormulaireItem(vm.nameElementIntervalleMax, {...optionsBase, titre: vm.lblChampMax})
        ])
    }

    function hasCustomValidations(): boolean {
        return Boolean(
            vm.dataType &&
            (
                vm.sousDataType instanceof NumberDataType ||
                vm.sousDataType instanceof DateDataType ||
                vm.sousDataType instanceof DateHeureDataType ||
                vm.sousDataType instanceof LovDataType
            )
        );
    }
}
