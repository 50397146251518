import {IComponentController} from 'angular';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import {IBlocBehavior} from '../../behaviors/ex-bloc/ex-bloc.controller';
import {IComposantMonoOccurrence} from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {FormulaireElement, IFormulaire, IFormulaireClass} from '../../services/formulaire/formulaire.service';
import {
    IFormulaireItem,
    IFormulaireItemClass,
    IFormulaireItemFormulaireConditionnel
} from '../../services/formulaire/formulaire-item.service';
import {IFormulaireItemTexteClass} from '../../services/formulaire/formulaire-item-texte.service';
import {
    IBoutonRadioDataType,
    IBoutonRadioDataTypeClass
} from '../../services/data-types/bouton-radio-data-type.service';
import {IBoutonRadioConfig} from '../../services/data-types/data-type.service';
import {INotificationHandler} from '../../services/utils/notification-handler.service';
import {IMenuItemClass} from '../../services/menu/menu-item.service';
import { groupBy } from '../../utils/list.utils';

interface IComposantCardQuestionnaire extends IComponentController {
    blocCtrl: IBlocBehavior;
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    formulaires: Array<IFormulaire>;
    formDataList: Array<any>;
    lblTitre: string;
    lblTxtAide: string;
    monoOccurrenceCtrl?: IComposantMonoOccurrence;
    retry(): void;
}

interface IQuestionnaireInfos {
    [col: string]: string|number;
    lbl_auc_reponse: string;
    // Questionnaire
    qstcleint: number;      // Clé interne du questionnaire
    qstdsc: string;         // Nom du questionnaire
    qsttxtint: string;      // Texte d'intro du questionnaire
    //Regroupement de question
    grqcleint: number;      // Clé interne d'un groupe de questions
    grqnom: string;         // Nom du groupe de questions
    grqseqaff: number;      // Séquence d'affichage d'un groupe de questions
    grqflgexc: number;      // Flag représentant si un groupe de questions est exclusif
    grqtxtint: string;      // Texte d'intro d'un groupe de question
    // Question
    qsdcleint: number;      // Clé interne d'une question
    qsdnom: string;         // Nom d'une question
    qsdseqaff: number;      // Séquence d'affichage de la question
    qsdtxtqst: string;      // Texte de la question
    qsdtxtint: string;      // Explication longue d'une question
    qsdtxtintval: string;   // Explication des réponses en mode "sous-niveau"
    qsdflgaut: number;      // Indicateur si la question permet "autre valeur"
    qsdtxtaut: string;      // Texte réponse "autre valeur"
    qsdflgnepasrep: number; // Indicateur si la question permet "ne pas répondre"
    qsdlibnepasrep: string; // Texte bouton "ne pas répondre"
    qsdflgon: number;       // Indicateur si la question est de type "Oui ou non"
    //Réponse
    qdvcleint: number;       // Clé interne d'une réponse
    qdvcleint_choix: number; // Clé interne de la réponse enregistrée
    qdvseq: number;          // Séquence d'affichage d'une réponse
    qdvtxtval: string;       // Texte de la réponse
    qdvtxtexp: string;       // Explication de la réponse
}

interface IRegroupementsQuestions {
    [titre: string]: Array<IQuestionnaireInfos>;
}

/* @ngInject */
export default function CardQuestionnaireController(BoutonRadioDataType: IBoutonRadioDataTypeClass,
                                                    Formulaire: IFormulaireClass,
                                                    FormulaireItem: IFormulaireItemClass,
                                                    FormulaireItemTexte: IFormulaireItemTexteClass,
                                                    MenuItem: IMenuItemClass,
                                                    MultiOccurrence: IMultiOccurrenceClass,
                                                    notificationHandler: INotificationHandler) {
    const vm: IComposantCardQuestionnaire = this;
    const OPTIONS_FORMULAIRE_ITEM_DEFAUT = {largeur: 100, titre: ' '};
    const OPTIONS_DIALOG_INFO_DEFAUT = {lblTitre: 'G_LBL_EXPLICATIONS', icon: 'info_outline'};

    vm.$onInit = $onInit;
    vm.retry = retry;

    function $onInit() {
        vm.multiOccurrenceOptions = {
            fonctions: {
                rechercheSansLimite: true,
                selectionnerUnEtat: false,
                enregistrerUnEtat: false,
                saveEtatTemporaire: false
            },
            bloc: vm.blocCtrl.bloc,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            colonnesVisibles: [
                'qstdsc',
                'qsttxtint',
                'grqnom',
                'grqtxtint',
                'qsdnom',
                'qsdtxtqst',
                'qsdtxtint',
                'qsdtxtintval',
                'qsdtxtaut',
                'qsdlibnepasrep',
                'qstcleint',
                'grqcleint',
                'grqseqaff',
                'grqflgexc',
                'qsdcleint',
                'qsdseqaff',
                'qsdflgon',
                'qsdflgaut',
                'qsdflgnepasrep',
                'qdvseq',
                'qdvcleint',
                'qdvcleint_choix',
                'qdvtxtval',
                'qdvtxtexp',
                'qsrtxtaut',
                'qsrflgon',
                'lbl_auc_reponse'
            ]
        };

        if (vm.monoOccurrenceCtrl) {
            if (vm.monoOccurrenceCtrl.monoOccurrence.dataReady) {
                vm.multiOccurrenceOptions.parentId = vm.monoOccurrenceCtrl.monoOccurrence.data.$id;
                initMultiOccurrence();
            } else {
                vm.monoOccurrenceCtrl.monoOccurrence.once('dataUpdate', () => {
                    vm.multiOccurrenceOptions.parentId = vm.monoOccurrenceCtrl.monoOccurrence.data.$id;
                    initMultiOccurrence();
                });
            }
        } else {
            initMultiOccurrence();
        }
    }

    function retry(): void {
        vm.multiOccurrence.initError ?
            vm.multiOccurrence.init() :
            vm.multiOccurrence.fetchDataList();
    }

    function initMultiOccurrence(): void {
        vm.multiOccurrence = new MultiOccurrence(vm.multiOccurrenceOptions);

        vm.multiOccurrence.once('dataListUpdate', () => {
            vm.lblTitre = vm.multiOccurrence.dataList[0].qstdsc;
            vm.lblTxtAide = vm.multiOccurrence.dataList[0].qsttxtint;
            initFormulaire(vm.multiOccurrence.dataList);
        });
    }

    function initFormulaire(dataList: Array<IQuestionnaireInfos>): void {
        const regroupementsQuestions: IRegroupementsQuestions = groupBy(dataList, 'grqseqaff', 'grqnom');
        vm.formDataList = [];

        vm.formulaires = Object.keys(regroupementsQuestions).reduce((groupes: Array<IFormulaire>, titreRegroupement: string) => {
            const questionsReponses: IRegroupementsQuestions = groupBy(regroupementsQuestions[titreRegroupement], 'qsdcleint', 'qsdnom');
            const regroupementType = regroupementsQuestions[titreRegroupement][0];
            const formulaireItemTexteIntroGroupe = new FormulaireItemTexte(regroupementType.grqtxtint, {customClass: 'p-b-8 ex-couleur-texte-second'});

            if (regroupementType.grqflgexc) {
                const formulairesItems = getFormulaireItemsQuestionExclusif(questionsReponses);
                if (regroupementType.grqtxtint) {
                    formulairesItems.unshift(formulaireItemTexteIntroGroupe);
                }

                groupes.push(new Formulaire(titreRegroupement, formulairesItems));
            } else {
                Object.keys(questionsReponses).forEach((titreQuestion: string, index: number) => {
                    const formulairesItems = getFormulaireItemsNonExclusif(questionsReponses[titreQuestion]);
                    if (!index && regroupementType.grqtxtint) {
                        formulairesItems.unshift(formulaireItemTexteIntroGroupe);
                    }

                    groupes.push(new Formulaire(!index ? titreRegroupement : null, formulairesItems));
                });
            }
            return groupes;
        }, []);
    }

    function getFormulaireItemsQuestionExclusif(questionsReponses: IRegroupementsQuestions): Array<FormulaireElement> {
        const boutonsRadio: Array<IBoutonRadioConfig> = [];
        const cleint = vm.multiOccurrence.cleint;
        let boutonRadioNePasRepondre: IBoutonRadioConfig;
        let formulairesConditionnels: Array<IFormulaireItemFormulaireConditionnel> = [];

        Object.keys(questionsReponses).forEach((titre: string, index: number) => {
            const reponses = questionsReponses[titre];
            const reponseType = reponses[0];
            const autresValeurs: {[key: string]: any} = {[cleint]: reponseType[cleint], qdvcleint: null, qsrflgon: 1, qsrtxtaut: null};

            if (!index) {
                boutonRadioNePasRepondre = { libelle: reponseType.lbl_auc_reponse, valeur: -1, autresValeurs: {qsrflgon: 0, qsrtxtaut: null} };
            }

            if (reponses.length > 1 || reponseType.qsdtxtaut) {
                const formulaireItems = [
                    new FormulaireItemTexte(reponseType.qsdtxtintval),
                    getFormulaireItemReponses(reponses)
                ];

                formulairesConditionnels.push({
                    valeur: reponseType.qsdcleint,
                    formulaire: new Formulaire(formulaireItems)
                });
            }

            const menus = reponseType.qsdtxtint ?
                [new MenuItem(
                    (ev: MouseEvent) => notificationHandler.info({...OPTIONS_DIALOG_INFO_DEFAUT, lblMessage: reponseType.qsdtxtint, targetEvent: ev}),
                    {icon: 'info_outline', iconButton: true, iconColor: '#607d8b'}
                )] :
                null;

            boutonsRadio.push({
                libelle: reponseType.qsdtxtqst,
                valeur: reponseType.qsdcleint,
                autresValeurs,
                menus
            });
        });

        if (boutonRadioNePasRepondre) {
            boutonsRadio.push(boutonRadioNePasRepondre);
        }

        const dataType = new BoutonRadioDataType({
            params: {
                boutonsRadio
            }
        });

        if (!formulairesConditionnels.length) {
            formulairesConditionnels = undefined;
        }

        const defaultQsdcleint = +getDefaultValueExclusif(questionsReponses);

        return [
            new FormulaireItem('qsrflgon', {hidden: true, default: (isNaN(defaultQsdcleint)?0:defaultQsdcleint) > 0 ? 1 : 0}),
            new FormulaireItem(cleint, {default: dataType.params.boutonsRadio[0].autresValeurs[cleint], hidden: true}),
            new FormulaireItem('qsdcleint',  {
                ...OPTIONS_FORMULAIRE_ITEM_DEFAUT,
                dataType,
                direction: 'vertical',
                default: (isNaN(defaultQsdcleint)?0:defaultQsdcleint),
                formulairesConditionnels
            })
        ];
    }

    function getFormulaireItemsNonExclusif(reponses: Array<IQuestionnaireInfos>): Array<FormulaireElement> {
        const formulaireItems: Array<FormulaireElement> = [];
        const reponseType = reponses[0];
        const formulaireItemQuestion = new FormulaireItemTexte(
            reponseType.qsdtxtqst,
            {
                customClass: 'ex-couleur-texte-noir ex-texte-gras',
                menu: [new MenuItem(
                    (ev: MouseEvent) => notificationHandler.info({...OPTIONS_DIALOG_INFO_DEFAUT, lblMessage: reponseType.qsdtxtint, targetEvent: ev}),
                    {icon: 'info_outline', iconButton: true, iconColor: '#607d8b', hidden: !reponseType.qsdtxtint}
                )]
            }
        );

        // On ajoute le id en mode caché pour qu'il fasse partie des données.
        formulaireItems.push(new FormulaireItem(vm.multiOccurrence.cleint, {default: reponseType[vm.multiOccurrence.cleint], hidden: true}));
        formulaireItems.push(formulaireItemQuestion);

        if (reponseType.qsdflgon) {
            let formulairesConditionnels: Array<IFormulaireItemFormulaireConditionnel>;

            const dataType = getDataTypeOuiNonAutre(reponseType);

            if (reponses.length > 1 || reponseType.qsdtxtaut) {
                formulairesConditionnels = [{ valeur: 1, formulaire: new Formulaire([getFormulaireItemReponses(reponses)])}];
            }

            formulaireItems.push(new FormulaireItem('qsrflgon', {
                ...OPTIONS_FORMULAIRE_ITEM_DEFAUT,
                dataType,
                direction: 'vertical',
                default: (reponseType.qsrflgon === null && !reponseType.qsrtxtaut) ? null : reponseType.qsrflgon,
                formulairesConditionnels
            }));
        } else if (reponses.length > 1 || reponseType.qsdtxtaut) {
            formulaireItems.push(getFormulaireItemReponses(reponses));
        }

        return formulaireItems;
    }

    function getFormulaireItemReponses(reponses: Array<IQuestionnaireInfos>): IFormulaireItem {
        const dataType = getReponsesDataType(reponses);
        const valeurDefaut: number = getReponseDefaultValue(reponses);
        const reponseType: IQuestionnaireInfos = reponses[0];
        let formulaireItemAutre: IFormulaireItem;

        if (reponseType.qsdflgaut) {
            dataType.params.boutonsRadio.push({libelle: reponseType.qsdtxtaut, valeur: -1, alignment: 'horizontal'});
            formulaireItemAutre = new FormulaireItem('qsrtxtaut', {titre: 'G_LBL_PRECISEZ', default: reponseType.qsrtxtaut, required: true});
        }

        return new FormulaireItem('qdvcleint', {
            ...OPTIONS_FORMULAIRE_ITEM_DEFAUT,
            dataType,
            default: valeurDefaut,
            direction: 'vertical',
            formulairesConditionnels: formulaireItemAutre && [{ valeur: -1, formulaire: new Formulaire([formulaireItemAutre])}]
        });
    }

    function getDataTypeOuiNonAutre(reponse: IQuestionnaireInfos): IBoutonRadioDataType {
        const boutonsRadio = [
            {
                libelle: 'G_LBL_OUI',
                valeur: 1
            },
            {
                libelle: 'G_LBL_NON',
                valeur: 0
            }
        ];

        if (reponse.qsdflgnepasrep) {
            boutonsRadio.push(
                {
                    libelle: reponse.qsdlibnepasrep,
                    valeur: 2
                }
            );
        }

        return new BoutonRadioDataType({
            params: {
                boutonsRadio
            }
        });
    }

    function getReponsesDataType(reponses: Array<IQuestionnaireInfos>): IBoutonRadioDataType {
        const boutonsRadioReponses: Array<IBoutonRadioConfig> = reponses
            .map((reponse: IQuestionnaireInfos) => ({libelle: reponse.qdvtxtval, valeur: reponse.qdvcleint, libelleAide: reponse.qdvtxtexp ? `(${reponse.qdvtxtexp})` : null}));

        return new BoutonRadioDataType({
            params: {
                boutonsRadio: boutonsRadioReponses
            }
        });
    }

    function getReponseDefaultValue(reponses: Array<IQuestionnaireInfos>): number {
        const reponseType = reponses[0];
        if (reponseType.qdvcleint_choix !== null) {
            return reponseType.qdvcleint_choix;
        } else if (reponseType.qsrtxtaut) {
            return -1;
        } else {
            return null;
        }
    }

    function getDefaultValueExclusif(questionsReponses: IRegroupementsQuestions): number|string {
        let isFirstVisit: boolean = true;
        const reponseChoisie = Object.keys(questionsReponses).map((titre: string) => questionsReponses[titre]).find((reponses: Array<IQuestionnaireInfos>) => {
            const reponseType = reponses[0];

            if (isFirstVisit && reponseType.usrmod) {
                isFirstVisit = false;
            }

            return reponseType.qsrflgon;
        });

        return reponseChoisie ?
            reponseChoisie[0].qsdcleint :
            (isFirstVisit) ? null : -1;
    }

}
