import {IComponentController} from 'angular';
import {IMenu} from '../../services/menu/menu.service';
import {IMenuItem} from '../../services/menu/menu-item.service';

export interface IRegroupementController extends IComponentController {
    blocsVisibles: Array<string>;
    isToujoursVisible?: boolean;
    register(bloc: string): void;
    menu: IMenu;
    isEveryMenuItemDisabled(): boolean;
}

/* @ngInject */
export default function RegroupementController() {
    const vm: IRegroupementController = this;

    vm.$onInit = $onInit;
    vm.register = register;
    vm.isEveryMenuItemDisabled = isEveryMenuItemDisabled;

    function $onInit() {
        vm.blocsVisibles = [];
    }

    function register(bloc: string): void {
        vm.blocsVisibles.push(bloc);
    }

    function isEveryMenuItemDisabled(): boolean {
        return vm.menu.listeMenuItem.every((menuItem: IMenuItem) => {
            if (menuItem.disabled instanceof Function) {
                return menuItem.disabled({});
            } else {
                return menuItem.disabled;
            }
        });
    }
}
