import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import FabSpeedDialCircleController from './ex-fab-speed-dial-circle.controller';

export default module('core.components.ex-fab-speed-dial-circle', [])
    .component('exFabSpeedDialCircle', FabSpeedDialCircleFactory());

function FabSpeedDialCircleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MENU,
            Binding.DATA,
            Binding.LIBELLES.OPTIONAL,{
                opened: '=?',
                unique: '<?'
            }
        ),
        require: {
            multiOccurrenceCtrl: '^^exMultiOccurrence'
        },
        controller: FabSpeedDialCircleController,
        controllerAs: 'vm',
        template: require('./ex-fab-speed-dial-circle.html')
    };
}
