import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputParametreMultipleController from './ex-input-parametre-multiple.controller';

export default module('core.components.ex-input-parametre-multiple', []).component('exInputParametreMultiple', InputParametreMultipleFactory());

function InputParametreMultipleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            Binding.LIBELLES.OPTIONAL,
            Binding.DATA,
            Binding.DATA_TYPE,
            Binding.OCCURRENCE,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                titre: '@',
                required : '<?ngRequired',
                resourceParams: '<?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputParametreMultipleController,
        template: require('./ex-input-parametre-multiple.html')
    };
}
