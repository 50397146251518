import {IComponentController, IScope} from 'angular';
import {IMenuItem} from '../../services/menu/menu-item.service';
import {IParametreMultipleDataType} from '../../services/data-types/parametre-multiple-data-type.service';
import {IIntervalleDataType, IIntervalleDataTypeClass} from '../../services/data-types/intervalle-data-type.service';
import {IColonne, IMultiOccurrenceOptions} from '../../services/multi-occurrence.service';
import {IMenuItemMultiOccurrenceClass} from '../../services/menu/menu-item-multi-occurrence.service';
import {IFormulaireClass} from '../../services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../services/formulaire/formulaire-item.service';
import {ILovDataTypeClass} from '../../services/data-types/lov-data-type.service';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IDateHeureDataTypeClass} from '../../services/data-types/date-heure-data-type.service';
import {IDataType} from '../../services/data-types/data-type.service';
import {IData} from '../../services/data-linker.service';
import { ILibelles } from '../../services/libelles.service';
import { IOccurrence } from '../../services/occurrence.service';

interface IComposantInputParametreMultiple extends IComponentController {
    menuItemEdition: IMenuItem;
    data: IData;
    value: Array<string>;
    affichage: string;
    valueDatalist: Array<string>;
    titre: string;
    dataType: IParametreMultipleDataType;
    sousDataType: IDataType;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    isIntervalleMultiple: boolean;
    isLov: boolean;
    resourceParams: any;
    disabled?: boolean;
    libelles: ILibelles;
    occurrence: IOccurrence;
}

/* @ngInject */
export default function InputParametreMultipleController(IntervalleDataType: IIntervalleDataTypeClass,
                                                         DateHeureDataType: IDateHeureDataTypeClass,
                                                         LovDataType: ILovDataTypeClass,
                                                         Formulaire: IFormulaireClass,
                                                         FormulaireItem: IFormulaireItemClass,
                                                         MenuItemMultiOccurrence: IMenuItemMultiOccurrenceClass,
                                                         defaults: IDefaultsService,
                                                         $scope: IScope) {
    const vm: IComposantInputParametreMultiple = this;
    const ICON = 'playlist_add';
    let colsRestrictions: Array<string> = [];

    vm.$onInit = $onInit;

    function $onInit() {
        defaults(vm, {
            isIntervalleMultiple: vm.dataType.dataTypeChamps instanceof IntervalleDataType,
            isLov: vm.dataType.dataTypeChamps instanceof LovDataType,
            valueDatalist: (vm.value || []),
            sousDataType: vm.dataType.dataTypeChamps || new LovDataType({
                schemaItem: {},
                params: vm.dataType.params
            })
        });

        if (vm.isLov && vm.dataType.dataTypeChamps.params.restrictions) {
            const dataTypeLov = vm.dataType.dataTypeChamps;
            colsRestrictions = Object.keys(dataTypeLov.params.restrictions || {});

            $scope.$watchCollection(
                () => {
                    return colsRestrictions.map((col: string) => vm.data[col]).every((val: string|number) => typeof val !== 'undefined' && val !== null);
                },
                (newValue: boolean) => {
                    if (!newValue) {
                        vm.value = null;
                        vm.disabled = true;
                    } else {
                        vm.disabled = false;
                    }
                });
        }

        vm.multiOccurrenceOptions = {
            formulaire: getFormulaire(),
            fonctions: {
                filtrer: false,
                recherche: false,
                importation: false,
                exportation: false,
                suiviModification: false,
                selectionnerUnEtat: false,
                enregistrerUnEtat: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                reinitialiser: false,
                afficherMenuSettingDansEntete: true,
                boutonDupliquer: false
            },
            entetesReplacedValues: {
                parametre_valeur: vm.titre
            },
            pagination: {
                nombreEnregistrementInMenuMore: false
            },
            dataTypes: getDataTypesColonnes(),
            autoFetch: false,
            bloc: 'mul',
            colonnesVisibles: getColonnesVisibles()
        };

        vm.menuItemEdition = new MenuItemMultiOccurrence('G_LBL_BTN_SAISIE_MULTIPLE', vm.multiOccurrenceOptions, {
            disabled: () => vm.disabled,
            iconButton: true,
            largeur: 40,
            icon: ICON,
            useLocalData: true,
            lblCancel: 'G_LBL_BTN_FERMER',
            datalist: () => (vm.valueDatalist),
            directionTooltip: 'bottom',
            onClose: () => {
                // Permet de s'assurer d'un affichage correct des données à la fermeture de la modale
                updateAffichage();
                vm.value = (vm.valueDatalist && vm.valueDatalist.length) ? formatDataFromList() : null;
            }
        });

        $scope.$watchCollection('vm.valueDatalist', () => {
            updateAffichage();
            vm.value = (vm.valueDatalist && vm.valueDatalist.length) ? formatDataFromList() : null;
        });

        if (vm.isLov && vm.dataType.dataTypeChamps.params.restrictions) {
            const dataTypeLov = vm.dataType.dataTypeChamps;
            const colsRestrictions = Object.keys(dataTypeLov.params.restrictions || {});

            $scope.$watchCollection(
                () => {
                    return colsRestrictions
                        .map((col: string) => vm.data[col])
                        .every((val: string|number) => typeof val !== 'undefined' && val !== null);
                },
                (newValue: boolean) => {
                    if (!newValue) {
                        vm.valueDatalist = [];
                        vm.disabled = true;
                    } else {
                        vm.disabled = false;
                    }
                });
        }

        // Permet pour GS0090 de pousser les données
        $scope.$watchCollection('vm.data[vm.nameElement + \'_multi\']', () => {
            if (vm.data[vm.nameElement + '_multi']) {
                vm.valueDatalist = vm.data[vm.nameElement + '_multi'];
            }
        });
    }

    function getFormulaire() {
        const formulaireItems = [];
        const resourceParamsValues = getResourceParamsValues();

        if (vm.isIntervalleMultiple) {
            const dataType = (<IIntervalleDataType>vm.sousDataType).dataTypeChamps;
            const resourceParams = dataType instanceof LovDataType ? resourceParamsValues : {};

            formulaireItems.push(
                new FormulaireItem('intervalle', {dataType: vm.sousDataType, largeur: 100, required: true, titre: vm.titre, resourceParams})
            );
        } else {
            const resourceParams = (vm.isLov) ? resourceParamsValues : {};
            formulaireItems.push(new FormulaireItem('parametre_valeur', {dataType: vm.sousDataType, required: true, resourceParams, titre: vm.titre}));
        }

        if (colsRestrictions.length) {
            formulaireItems.push(...colsRestrictions.map((col: string) => new FormulaireItem(col, {default: () => (vm.data[col]), hidden: true})));
        }

        return new Formulaire(vm.titre, formulaireItems);
    }

    function updateAffichage(): void {
        vm.affichage = vm.valueDatalist.reduce((value: string, data: any, index: number) => {
            if (index > 0) {
                value = `${value}; ${getTemplateFromDataType(data)}`;
            } else {
                value = getTemplateFromDataType(data);
            }

            return value;
        }, '');
    }

    function getTemplateFromDataType(data: any): string {
        if (vm.isIntervalleMultiple) {
            const isDateHeure = (<IIntervalleDataType>vm.sousDataType).dataTypeChamps instanceof DateHeureDataType;
            const valueDe = (isDateHeure) ? data.intervalle_min.replace('T', ' ') : data.intervalle_min__code ? data.intervalle_min__code : data.intervalle_min;
            const valueA = (isDateHeure) ? data.intervalle_max.replace('T', ' ') : data.intervalle_max__code ? data.intervalle_max__code : data.intervalle_max;
            return `${valueDe} - ${valueA}`;
        } else if (vm.isLov) {
            return `${data.parametre_valeur__code} - ${data.parametre_valeur__description}`;
        } else if (vm.dataType.params.description) {
            return vm.dataType.params.description.map(champ => data['parametre_valeur__' + champ]).join(' - ');
        } else {
            return data.parametre_valeur;
        }
    }

    function getDataTypesColonnes(): any {
        const dataType = (vm.isIntervalleMultiple) ?
            (<IIntervalleDataType>vm.sousDataType).dataTypeChamps : vm.sousDataType;
        if (vm.isIntervalleMultiple) {
            return {
                intervalle_min: dataType,
                intervalle_max: dataType
            };
        } else {
            return {
                parametre_valeur: dataType
            };
        }
    }

    function getColonnesVisibles(): Array<IColonne|string> {
        if (vm.isIntervalleMultiple) {
            return [{nom: 'intervalle_min', largeur: 250}, {nom: 'intervalle_max', largeur: 250}];
        } else if (vm.isLov) {
            return [{nom: 'parametre_valeur__code', largeur: 150}, {nom: 'parametre_valeur__description', largeur: 500}];
        } else {
            return [{nom: 'parametre_valeur', largeur: 250}];
        }
    }

    function getResourceParamsValues() {
        if (vm.resourceParams instanceof Function) {
            return vm.resourceParams(vm.data);
        } else {
            return vm.resourceParams || {};
        }
    }

    function formatDataFromList() {
        return vm.valueDatalist.map((rowData: any) => {
            if (vm.isIntervalleMultiple) {
                return [rowData.intervalle_min, rowData.intervalle_max];
            } else {
                return rowData.parametre_valeur;
            }
        });
    }
}
