import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CardMultiHierarchieController from './ex-card-multi-hierarchie.controller';

export default module('core.components.ex-card-multi-hierarchie', []).component('exCardMultiHierarchie', CardMultiHierarchieFactory());

function CardMultiHierarchieFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.OPENED.OPTIONAL,
            Binding.COLLAPSABLE.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            {
                lblTitre: '@',
                multiOccurrence: '=?multiOccurrenceOut',
                expandedRows: '<?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran'
        },
        transclude: {
            actions: '?exCardMultiHierarchieActions'
        },
        controller: CardMultiHierarchieController,
        controllerAs: 'vm',
        template: require('./ex-card-multi-hierarchie.html')
    };
}
