
interface IRow {[key: string]: any}

interface IGroupedResult {[key: string]: Array<any>}

export function groupBy(datalist: Array<IRow>, colonneGroupBy: string, colonneTitre?: string): IGroupedResult {
    const tree: IGroupedResult = datalist
        .reduce((tree: IGroupedResult, row: IRow) => {
            const key = row[colonneGroupBy];
            if (!tree[key]) {
                tree[key] = [];
            }

            tree[key].push(row);
            return tree;
        }, {});


    if (colonneTitre) {
        Object.keys(tree).forEach((col: string) => {
            if (!tree[col].length) {
                delete tree[col];
            } else {
                // On ajuste le titre
                const titre = tree[col][0][colonneTitre];
                tree[titre] = tree[col];
                delete tree[col];
            }
        });
    }

    return tree;
}