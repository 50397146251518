import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import BoutonCompteurPiecesJointesController from './ex-bouton-compteur-pieces-jointes.controller';

export default module('app.components.ex-bouton-compteur-pieces-jointes', []).component('exBoutonCompteurPiecesJointes', BoutonCompteurPiecesJointesFactory());

function BoutonCompteurPiecesJointesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.MULTI_OCCURRENCE,
            Binding.COL
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: BoutonCompteurPiecesJointesController,
        template: require('./ex-bouton-compteur-pieces-jointes.html')
    };
}
