import IComponentController = angular.IComponentController;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import { IMultiOccurrence, IMultiOccurrenceClass } from '../../services/multi-occurrence.service';
import { IPaginationClass } from '../../services/pagination.service';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IDialog } from '../../services/dialog.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantInfosComplementaires extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    groupedAttributs: Array<IAttributsGroup>;
    compteur: number;

    openRow(event: MouseEvent, rowData: IAttributInfosComplementaires, titre: string): void;
    retryErreur(): void;
    getValeur(data: IAttributInfosComplementaires): string;
}

interface IAttributsGroup {
    ticcleint: number;
    attributs: Array<IAttributInfosComplementaires>;
}

export interface IAttributInfosComplementaires {
    astflgsai: number;
    astordaff: number;
    flglov: boolean;
    flgmulti: boolean;
    icocleint: number;
    icoflgfix: boolean;
    icoflgjus: boolean;
    icolib: string;
    icolng: number;
    icvcleint: number;
    icvcleintref: number;
    icvvaluni: string;
    srccleint: number;
    stacleint: number;
    ticcleint: number;
    ticcod: string;
    ticdsc: string;
    vaesystypdon: string;
    vaesystyplet: string;
    valeur: string;
}

/* @ngInject */
export default function InfosComplementairesController(MultiOccurrence: IMultiOccurrenceClass,
    Pagination: IPaginationClass,
    $element: IAugmentedJQuery,
    DialogInfosComplementaires: IDialog) {

    const vm: IComposantInfosComplementaires = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.retryErreur = retryErreur;
    vm.getValeur = getValeur;
    vm.openRow = openRow;

    function $onInit() {
        $element.closest('md-content.ex-mono-occurrence-ecran-content').animate({
            scrollTop: $element.prop('offsetTop') - 8
        }, 200);

        vm.multiOccurrence = new MultiOccurrence({
            srccod: 'mc1022',
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            resourceParams: {
                srccod: vm.ecranContextCtrl.stateParams.srccod
            },
            parentId: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
            colonnesVisibles: [
                'astflgsai',
                'astordaff',
                'flglov',
                'flgmulti',
                'icocleint',
                'icoflgfix',
                'icoflgjus',
                'icolib',
                'icolng',
                'icvcleint',
                'icvcleintref',
                'icvvaluni',
                'srccleint',
                'stacleint',
                'ticcleint',
                'ticcod',
                'ticdsc',
                'vaesystypdon',
                'vaesystyplet',
                'valeur'
            ],
            pagination: new Pagination({
                nombreElementPage: 500
            }),
            onDelete: () => vm.compteur--
        });
        vm.multiOccurrence.on('dataListUpdate', groupAttributs);
    }

    function $onDestroy() {
        vm.multiOccurrence.removeListener('dataListUpdate', groupAttributs);
    }

    function groupAttributs() {
        vm.compteur = vm.multiOccurrence.compteur
        vm.groupedAttributs = vm.multiOccurrence.dataList.reduce((groups: Array<any>, row: any) => {
            if (!groups.length || groups[groups.length - 1].ticcleint !== row.ticcleint) {
                groups.push({
                    ticcleint: row.ticcleint,
                    titre: row.ticdsc,
                    attributs: [row]
                });
            } else {
                groups[groups.length - 1].attributs.push(row);
            }

            return groups;
        }, []);
    }

    function openRow(event: MouseEvent, rowData: IAttributInfosComplementaires, titre: string) {
        DialogInfosComplementaires.show({
            targetEvent: event,
            focusOnOpen: false,
            locals: {
                lblTitre: titre,
                data: rowData,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                ecranContext: vm.ecranContextCtrl
            }
        }).finally(() => {
            vm.multiOccurrence.fetchDataList()
        });
    }

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function getValeur(data: IAttributInfosComplementaires) {
        return data.flgmulti ? data.valeur : data.icvvaluni;
    }
}
