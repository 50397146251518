import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import RegroupementController from "./ex-regroupement.controller";

export default module('core.components.ex-regroupement', []).component('exRegroupement', RegroupementFactory());

function RegroupementFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MENU.OPTIONAL,
            {
                opened: '=?',
                lblTitre: '@',
                disabled: '<?ngDisabled'
            }
        ),
        transclude: {
            actions: '?exRegroupementActions',
            content: '?exRegroupementContent'
        },
        controller: RegroupementController,
        controllerAs: 'vm',
        template: require('./ex-regroupement.html')
    };
}
