import {IComponentOptions, module} from 'angular';
import DataBarListController from './ex-data-bar-list.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-bar-list', []).component('exDataBarList', DataBarListFactory());

function DataBarListFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COLS,
            Binding.DATA.OPTIONAL,
            Binding.MENUS.OPTIONAL,
            {
                forcedMaxValue: '<?maxValue',
                affichageFraction: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: DataBarListController,
        template: require('./ex-data-bar-list.html')
    };
}
