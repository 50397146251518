import { IAugmentedJQuery, IComponentController } from 'angular';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IExAugmentedEvent } from '../../interfaces/event.interface';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataLinker } from '../../services/data-linker.service';
//import { IExpandableBehaviorController } from '../../behaviors/ex-expandable/ex-expandable.behavior';

export interface IComposantHierarchieItem extends IComponentController {
    data: any
    multiOccurrence: IMultiOccurrence;
    validateOpen(id: number, hasEnfants: boolean, event: IExAugmentedEvent): void;
    itemOpen: string;
    hasDataDroite(data: any): boolean;
    preventExpandableAction(event: IExAugmentedEvent): void;
    nomItemLocalStorage: string;
    newline: boolean;
    iconMenu: Array<string>
    interdit: boolean
}

/* @ngInject */
export default function HierarchieItemController($element: IAugmentedJQuery, defaults: IDefaultsService, dataLinker: IDataLinker) {
    const vm: IComposantHierarchieItem = this;
    //index 0 dossier ferme, index 1 dossier ouvert, index 2 acces interdit
    vm.iconMenu = ['folder', 'folder_open', 'not_interested']
    vm.$onInit = $onInit;
    vm.hasDataDroite = hasDataDroite;
    vm.preventExpandableAction = preventExpandableAction;
    vm.validateOpen = validateOpen;
    function $onInit() {
        defaults(vm, {
            niveau: 0,
            nomItemLocalStorage: getNomItemLocalStorage(),
            newline: false,
        });

        dataLinker.link($element, vm.data, vm.multiOccurrence.stateParams, vm.multiOccurrence.ecranDetails);
        //on fait une validation afin d'afficher un md-divider
        if (vm?.data?.newline) {
            vm.newline = true
        }
        //on valide si l'icone doit etre interdit
        valideAccesInterdit()
        validateOpenRow()
    }

    function valideAccesInterdit() {
        vm.interdit = (vm?.data?.flgacces) ? true : false
        vm.itemOpen = (vm.interdit) ? vm.iconMenu[2] : vm.iconMenu[0]

    }
    function hasDataDroite(data: any): boolean {
        return data && vm.multiOccurrence.colonnesFixesDroite.some((col: string) => {
            return data[col] !== null && typeof data[col] !== 'undefined';
        });
    }

    function preventExpandableAction(event: IExAugmentedEvent): void {
        event.originalEvent.exExpandablePreventAction = true;
    }
    async function validateOpen(id: number, hasEnfants: boolean, event: Event) {
        event.stopPropagation();
        let valideClassCss = false;
        //s'il n'y a pas des enfants on ne fait pas la recherche
        if (!hasEnfants)
            return
        //on fait la recherche de l'element html par id
        const findIcon = $element.find('#' + id).children()
        if (findIcon && findIcon[0]) {
            //on valide si la class css expandable est collapsed ou expanded
            valideClassCss = findIcon[0]?.className?.includes("ex-expandable--collapsed") || false
        }

        setOpenRows(id, valideClassCss)
        //on change d'icon selon la class css expanded icon ouvert, collapsed icon ferme, acces interdit icon rouge
        vm.itemOpen = (vm.interdit) ? vm.iconMenu[2] : (valideClassCss) ? vm.iconMenu[1] : vm.iconMenu[0]
    }

    function setOpenRows(id: number, flag: boolean) {
        //on obtiens l'information du local storage
        let data: any = getDataLocal()
        if (data.length > 0) {
            //on elimine le locastorage et on ajoute l'id modifie
            localStorage.removeItem(vm.nomItemLocalStorage)
            data = data.filter((el: any) => el.id !== id)
        }
        //on garde l'etat du menu ouvert ou ferme
        data.push({ id: vm.data.$id, open: flag, niveau: vm.data.niveau })
        localStorage.setItem(vm.nomItemLocalStorage, JSON.stringify(data))
    }

    function validateOpenRow() {
        let data: any = getDataLocal()
        //on valide qu'il y a information enregistre dans le localstorage
        if (data) {
            const valide = data.filter((el: any) => el.id === vm.data.$id)
            //on valide si l'icone doit etre ouvert, ferme ou interdit
            if (valide && valide.length > 0 && valide[0].id === vm.data.$id) {
                vm.expandedRows = valide[0].open
                vm.itemOpen = (vm.interdit) ? vm.iconMenu[2] : (valide[0].open) ? vm.iconMenu[1] : vm.iconMenu[0]
            }
        }
    }

    function getDataLocal() {
        //gestion du localstorage
        let data: any = []
        if (localStorage.getItem(vm.nomItemLocalStorage)) {
            data = JSON.parse(localStorage.getItem(vm.nomItemLocalStorage))
        }
        return data
    }

    function getNomItemLocalStorage() {
        //on obtiens un key pour le localStorage par ecran et id et bloc
        let id = (vm?.multiOccurrence?.stateParams?.id) ? vm?.multiOccurrence?.stateParams?.id + "" : 0 + ""
        let bloc = (vm?.multiOccurrence?.bloc) ? vm?.multiOccurrence?.bloc + "" : "bl0"
        let result = (vm?.multiOccurrence?.srccod) ? vm.multiOccurrence.srccod + id + bloc + "exHierarchieItem" : "noName";
        return result
    }
}