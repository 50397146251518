import { IAugmentedJQuery, IComponentController, IRootScopeService, IScope, ITimeoutService } from 'angular';
import { IMenu, IMenuClass } from "../../services/menu/menu.service";
import { IMenuItemClass } from "../../services/menu/menu-item.service";
import * as moment from 'moment/moment';
import { IMultiOccurrence } from "../../services/multi-occurrence.service";
import { IFiltre, IFiltreClass } from "../../services/filtre.service";
import AxesAffichage, { IAxeAffichageCalendrierOptions } from "../../classes/axe-affichage.class";
import { IOperateurService } from "../../services/operateur.service";
import { EventClickArg } from "@fullcalendar/core";
import { IFilterLibelle } from "../../filters/ex-libelle.filter";
import { IMenuItemForageClass } from "../../services/menu/menu-item-forage.service";
import { IStateParamsService } from "angular-ui-router";

interface ICalendrier extends IComponentController {
    colonneFiltreDate: string;
    multiOccurrence: IMultiOccurrence;
    calendar: any;
    menuNavigation: IMenu;
    menuViews: IMenu;
    options: IAxeAffichageCalendrierOptions;
    oldDate?: any;
    clickNavigateButton?: any;
}

interface ICalendrierEvenement {
    title: string;
    start: string;
    end?: string;
    className?: string;
    allDay?: boolean;
    editable?: boolean;
    data: any;
}

const OPTIONS_BASE_EVENEMENTS = {
    editable: false,
    className: 'ex-calendrier-event ex-primary-bg'
};
const MODES_NAMES = {
    MOIS: 'dayGridMonth',
    SEMAINE: 'timeGridWeek',
    JOUR: 'timeGridDay'
};
const MODES = {
    [MODES_NAMES.MOIS]: {
        lblTitre: 'G_LBL_CAL_MONTH',
        icon: 'view_comfy'
    },
    [MODES_NAMES.SEMAINE]: {
        lblTitre: 'G_LBL_CAL_SEMAINE',
        icon: 'view_week'
    },
    [MODES_NAMES.JOUR]: {
        lblTitre: 'G_LBL_CAL_DAY',
        icon: 'view_day'
    }
};
const MAXDAYEVENTDEFAULT = 3;
const MAXDAYEVENTSLONG = 15
/* @ngInject */
export default function CalendrierController($element: IAugmentedJQuery,
    $scope: IScope,
    Filtre: IFiltreClass,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass,
    Operateur: IOperateurService,
    exLibelleFilter: IFilterLibelle,
    $stateParams: IStateParamsService,
    $rootScope: IRootScopeService,
    $timeout: ITimeoutService) {
    const vm: ICalendrier = this;

    vm.$onInit = function $onInit() {
        vm.options = vm.multiOccurrence.axesAffichage.axeActive.options;

        if (!vm.options.navigatePage) {
            vm.options.navigatePage = vm.multiOccurrence.navigatePage;
        }

        if (!vm.options.navigateParams) {
            vm.options.navigateParams = vm.multiOccurrence.navigateParams;
        }

        Promise.all([import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/core"), import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/timegrid"), import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/daygrid")]).then(([{ Calendar }, timeGridPlugin, dayGridPlugin]) => {
            let eventClick = clickOnEvent
            if (vm.options.navigatePage) {
                eventClick = (evenement: EventClickArg) => {
                    new MenuItemForage('',
                        vm.options.navigatePage instanceof Function ? vm.options.navigatePage(evenement.event.extendedProps.data) : vm.options.navigatePage,
                        () => ({
                            id: evenement.event.extendedProps.data[vm.multiOccurrence.forageCleint],
                            ...(vm.options.navigateParams ? vm.options.navigateParams(evenement.event.extendedProps.data, vm.multiOccurrence) : {})
                        })
                    ).action(evenement.event.extendedProps.data, $stateParams, true);
                }
            }
            const valeur: any = vm?.multiOccurrence?.axesAffichage?.axeActive?.options?.defaultDate
            const day: any = {
                initialDate: (valeur) ? valeur : undefined
            }
            vm.calendar = new Calendar($element.find(".ex-calendrier-calendar")[0], {
                plugins: [dayGridPlugin.default, timeGridPlugin.default],
                locale: 'fr-ca',
                headerToolbar: false,
                dayMaxEventRows: 3,
                dayMaxEvents: MAXDAYEVENTDEFAULT,
                displayEventEnd: true,
                eventClick,
                dayHeaderFormat: { weekday: "long" },
                allDayText: exLibelleFilter("G_LBL_CAL_ALL_DAY"),
                moreLinkText: exLibelleFilter("G_LBL_CAL_AUTRES"),
                navLinks: true,
                navLinkDayClick: dayClicked => switchView(MODES_NAMES.JOUR, dayClicked),
                ...day
            })
            vm.colonneFiltreDate = [vm.options.colonneDateDebut, vm.options.colonneDateFin].join(',');

            initWatchers();
            updateFiltres();

            vm.menuNavigation = new Menu([
                new MenuItem('', () => {
                    vm.calendar.prev();
                    updateFiltres(true);
                }, {
                    iconButton: true,
                    icon: 'navigate_before'
                }),
                new MenuItem(`G_LBL_CALENDAR_TODAY`, () => {
                    vm.calendar.today();
                    updateFiltres(true)
                }, {
                    iconButton: false
                }),
                new MenuItem('', () => {
                    vm.calendar.next();
                    updateFiltres(true);
                }, {
                    iconButton: true,
                    icon: 'navigate_next'
                })
            ]);

            vm.menuViews = new Menu(() => MODES[vm.calendar.view.type].lblTitre,
                createMenuView(vm.options).map((el: any) => { return el }),
                {
                    icon: () => MODES[vm.calendar.view.type].icon,
                    iconFirst: true
                }
            );

            vm.calendar.render()
        })
    }
    function clickOnEvent(info: any) {
        switchView(MODES_NAMES.JOUR, info.event._instance.range.end)
    }
    $rootScope.$on('ex-calendrier.setDate', async (event, date) => {
        try {
            if (!event.defaultPrevented) {
                event.defaultPrevented = false;
                const dateNew = date?.newDate
                const oldDate = date?.oldDate;
                const currentDate = vm?.calendar?.currentData?.currentDate;
                const etatDate = vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.date;
                const axe = vm?.multiOccurrence?.axesAffichage?.axeActive.options?.setDate;
                //si le broadcast est call depuis le dialog action fermé
                if (!oldDate && !dateNew && axe) {
                    if (etatDate && currentDate) {
                        if (currentDate.toISOString().split('T')[0] != etatDate) {
                            await reinitialiser(etatDate).then(() => updateFiltres());
                        }
                    }
                } else {
                    //changer la date call fait depuis le composant pastille-filtre
                    if (vm.oldDate != dateNew) {
                        await reinitialiser(dateNew).then(() => updateFiltres());
                    }
                }
            }
        } catch (error) {
        }
    })

    function getMenuItem(view: any) {
        return new MenuItem(
            MODES[view].lblTitre,
            () => switchView(view),
            {
                icon: MODES[view].icon
            }
        )
    }

    function createMenuView(options: any) {
        let menu: any = Object.keys(MODES).map((view: string) => {
            switch (view) {
                case "timeGridDay":
                    if (typeof (options.showDay) === "undefined" || options.showDay) {
                        return getMenuItem(view);
                    }
                    break;
                case "timeGridWeek":
                    if (typeof (options.showWeek) === "undefined" || options.showWeek) {
                        return getMenuItem(view);
                    }
                    break;
                case "dayGridMonth":
                    if (typeof (options.showMonth) === "undefined" || options.showDay) {
                        return getMenuItem(view);
                    }
                    break;
                default:
                    break;
            }
        })
        return menu.filter((el: any) => el !== undefined);
    }
    function updateFiltres(buttonClick = false) {
        vm.clickNavigateButton = buttonClick
        vm.multiOccurrence.fonctions.pagination = false;
        vm.multiOccurrence.fonctions.rechercheSansLimite = true;

        if (!vm.multiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === vm.colonneFiltreDate && !filtre.visible)) {
            const filtreDate = new Filtre({
                colonne: [vm.options.colonneDateDebut, vm.options.colonneDateFin],
                operateur: Operateur.ENTRE,
                valeur: moment(vm.calendar.view.activeStart).format('YYYY-MM-DD'),
                valeur2: moment(vm.calendar.view.activeEnd).subtract(1, "days").format('YYYY-MM-DD'),
                visible: false
            });

            vm.multiOccurrence.etatSelected.addFiltre(filtreDate);
        } else {
            const filtreDate = vm.multiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === vm.colonneFiltreDate && !filtre.visible);
            const index = vm.multiOccurrence.etatSelected.filtres.indexOf(filtreDate);
            const nouveauFiltre = new Filtre({
                colonne: [vm.options.colonneDateDebut, vm.options.colonneDateFin],
                operateur: Operateur.ENTRE,
                valeur: moment(vm.calendar.view.activeStart).format('YYYY-MM-DD'),
                valeur2: moment(vm.calendar.view.activeEnd).subtract(1, "days").format('YYYY-MM-DD'),
                visible: false
            });

            vm.multiOccurrence.etatSelected.replaceFiltre(nouveauFiltre, index);
        }

        //si on fais click sur les boutons de navigation on selectionne le premier jour du mois
        if (buttonClick) {
            const firstDay = vm?.calendar?.getDate()?.toISOString()?.split('T')[0];
            if (firstDay) {
                vm.multiOccurrence.etatSelected.criteresSuggeresData.date = firstDay
                vm.multiOccurrence.etatSelected.criteresSuggeresData.datdef = firstDay
                $timeout(() => {
                    selectDate(firstDay)
                    vm.multiOccurrence.emit('etatUpdate');
                })
            }
        }
        vm.multiOccurrence.fetchDataList();
    }

    function initWatchers() {
        vm.multiOccurrence.on('dataListUpdate', addEvents);

        $scope.$on('$destroy', () => {
            vm.multiOccurrence.removeListener('dataListUpdate', addEvents);
        })
    }

    function addEvents() {
        const events: Array<ICalendrierEvenement> = [];

        for (const row of vm.multiOccurrence.dataList) {
            const start = getStartValue(row);
            if (start.length) {
                events.push({
                    ...OPTIONS_BASE_EVENEMENTS,
                    title: (vm.options.description || []).map((col: string) => row[col]).filter((value: any) => value).join(' - '),
                    start,
                    end: getEndValue(row),
                    allDay: isAllDayEvent(row),
                    data: row
                });
            }
        }

        vm.calendar.removeAllEvents();
        for (const event of events) {
            vm.calendar.addEvent(event);
        }
    }

    function getStartValue(data: any) {
        if (data[vm.options.colonneDateDebut]) {
            const date = data[vm.options.colonneDateDebut].split('T').shift();

            if (date.length && vm.options.colonneHeureDebut && data[vm.options.colonneHeureDebut]) {
                const heure = data[vm.options.colonneHeureDebut].split('T').pop();
                return `${date}T${heure}`;
            }
            return date
        }

        return "";
    }

    function getEndValue(data: any) {
        if (data[vm.options.colonneDateFin]) {
            const date = data[vm.options.colonneDateFin] && data[vm.options.colonneDateFin].split('T').shift();

            if (date.length && vm.options.colonneHeureFin && data[vm.options.colonneHeureFin]) {
                const heure = data[vm.options.colonneHeureFin].split('T').pop();
                return `${date}T${heure}`;
            }
            return date
        }
        return "";
    }

    function switchView(targetView: string, params?: any) {
        const dateNew = vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.date;
        vm.calendar.changeView(targetView, params);
        hideHours()
        if (targetView === MODES_NAMES.JOUR) {
            updateFiltres(true);
        } else {
            updateFiltres();
        }

        vm.multiOccurrence.axesAffichage.axeActive.options.viewMode = targetView
        if (dateNew) {
            selectDate(dateNew)
        }
    }

    function hideHours() {
        if (vm?.options?.hideHours && vm?.calendar?.currentData?.currentViewType === MODES_NAMES.JOUR) {
            //on va cacher en utilisant le texte '00 h' lorsqu'on a mis la propriete slotDuration: '24:00'
            setTimeout(function () {
                $("td:contains('00 h')").addClass('hidden-time');
            }, 0);

            vm.calendar.currentData.options.dayMaxEvents = MAXDAYEVENTSLONG
        } else {
            vm.calendar.currentData.options.dayMaxEvents = MAXDAYEVENTDEFAULT
        }
    }
    function isAllDayEvent(rowData: any) {
        const dateDebut = moment(rowData[vm.options.colonneDateDebut]);
        const dateFin = moment(rowData[vm.options.colonneDateFin]);
        return !rowData[vm.options.colonneHeureDebut] || !rowData[vm.options.colonneHeureFin] || !(dateDebut.isSame(dateFin, 'day') && dateDebut.isSame(dateFin, 'month') && dateDebut.isSame(dateFin, 'year'))
    }
    function selectDate(dateNew: any) {
        const colonne = (vm.options.colonneDateDebut + "," + vm.options.colonneDateFin).toString();
        if (dateNew) {
            $timeout(() => {
                if (vm.multiOccurrence.etatSelected.axeAffichageActif == AxesAffichage.AXES_AFFICHAGE.LIST) {
                    $rootScope.$broadcast('axe-calendar.show.grid', { colonne: colonne, date: dateNew });
                } else {
                    vm.calendar.select(dateNew)
                }
            }).then(() => {
                hideHours();
            })
        }
    }
    async function reinitialiser(dateNew: any) {
        return Promise.all([import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/core"), import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/timegrid"), import(/* webpackChunkName: "libs/fullcalendar" */ "@fullcalendar/daygrid")]).then(([{ Calendar }, timeGridPlugin, dayGridPlugin]) => {

            const valeur: any = dateNew;
            const day: any = {
                initialDate: (valeur) ? valeur : undefined
            }
            vm.calendar = new Calendar($element.find(".ex-calendrier-calendar")[0], {
                plugins: [dayGridPlugin.default, timeGridPlugin.default],
                locale: 'fr-ca',
                headerToolbar: false,
                dayMaxEventRows: 3,
                dayMaxEvents: MAXDAYEVENTDEFAULT,
                displayEventEnd: true,
                eventClick: clickOnEvent,
                dayHeaderFormat: { weekday: "long" },
                allDayText: exLibelleFilter("G_LBL_CAL_ALL_DAY"),
                moreLinkText: exLibelleFilter("G_LBL_CAL_AUTRES"),
                navLinks: true,
                navLinkDayClick: dayClicked => switchView(MODES_NAMES.JOUR, dayClicked),
                slotDuration: (vm?.options?.hideHours) ? '24:00' : '00:30:00',
                ...day
            })
            vm.calendar.render()
            //vm.calendar.changeView()
            const viewMode = vm?.multiOccurrence?.axesAffichage?.axeActive?.options?.viewMode;
            vm.calendar.changeView((viewMode) ? viewMode : "dayGridMonth");
            //pour le premier render on ne selectionne pas la date car lui est deja selectionné en jaune
            selectDate(dateNew)
            vm.oldDate = dateNew;
            vm.clickNavigateButton = false;
        })
    }
}
